@import url("https://use.typekit.net/zzr4enm.css");
$red: #e6001c;
$black: #222;
$white: #f1f2f3;
$borderWidth: 4px;
$paddingY: 0.35em;
$sigFont: filson-soft, sans-serif;



  //taco madeness
.promo-banner {
  // background: red;
  padding-top: 1em;
  .bg {
    margin: 1em auto;
    width: 80%;
    padding: 0.5em;
    border-radius: 1em;
    border: 4px dotted white;
    max-width: 500px;
  }
  .order-button {
    background-color: #0071f3 !important;
    box-shadow: 1px 1px #230577, 3px 3px #230577, 5px 5px #230577, 7px 7px #230577 !important;


  }
  text-align: center;
  h2#promo-title {
    font-size: 1.5em
    // margin-top: 1em;
  }
  p {
    text-align: center !important;
  }
}
.menu-nav-wrapper {
  background: #222 !important;
}
.site-content.pablitostacos {
  // height: 100vh;
  background-color: #fff8c2;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16'%3E%3Cg fill='%23eabe12' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 .04C2.6.22 4.99 1.1 7 2.5A13.94 13.94 0 0 1 15 0h4c.34 0 .67.01 1 .04v2A12 12 0 0 0 7.17 12h5.12A7 7 0 0 1 20 7.07V14a5 5 0 0 0-3-4.58A5 5 0 0 0 14 14H0V7.07c.86.12 1.67.4 2.4.81.75-1.52 1.76-2.9 2.98-4.05C3.79 2.83 1.96 2.2 0 2.04v-2z'/%3E%3C/g%3E%3C/svg%3E");



  .main-page.cart-wrapper {
    .cart.minimized {

      // background: black;
      border-bottom: 2px solid black;
    }
  }

  .home-nav {
    padding: 0.2em 0.4em;
    // background: rgba(255,255,255,0.5);
    background: white;
    border-bottom: 2px solid gray;
    display: flex;
    align-items: center;
    .logo {
      margin-top: -0.5em;
      width: 100px;
    }
    .nav-items {
      display: flex;
      flex: 1;
      justify-content: center;
      a {
        padding: 0 0.3em;
        display: inline-block;
        color: black;
        text-decoration: none;
        font-weight: 900;
      }
    }
  }
}
.pablitostacos, .pablitostacosburbank, .pablitostacosnoho, .pablitostacosnorth  {
  a.category-link-item {
    color: white !important;
    :visited {
      color: white !important;
    }
  }
  .menu-story {
    padding-top: 0;
  }
  .menu-nav-wrapper {
    background: #222;
    border-bottom-color: black;
  }
  font-family: $sigFont;
  .tax {
    font-size: 0.8em;
    
  }
  .menu-item {
    background: rgba(50,50,50,0.3) !important;
  }
  .product-link, .product-link:visited, {
    background-color: #eee;
    color: black;
  }
  .rewards-special {
    font-family: $sigFont;
  }
  img.nav-bar-logo {
    display: block;
    padding-left: 0.8em;
  }
  .cart.minimized {
    background-color: #fff8c2;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16'%3E%3Cg fill='%23eabe12' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 .04C2.6.22 4.99 1.1 7 2.5A13.94 13.94 0 0 1 15 0h4c.34 0 .67.01 1 .04v2A12 12 0 0 0 7.17 12h5.12A7 7 0 0 1 20 7.07V14a5 5 0 0 0-3-4.58A5 5 0 0 0 14 14H0V7.07c.86.12 1.67.4 2.4.81.75-1.52 1.76-2.9 2.98-4.05C3.79 2.83 1.96 2.2 0 2.04v-2z'/%3E%3C/g%3E%3C/svg%3E");
    padding-bottom: 0;
    padding-left: 0;
    margin-left: -0.5em;
    border: none;
  }
  .landing-page-wrapper {
    h2, h3 {
      font-family: $sigFont;
      text-align: center;
      padding: 1em 0.2em;
      font-size: 1em;
      font-weight: 900;
    }
    background-color: #fff8c2;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16'%3E%3Cg fill='%23eabe12' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 .04C2.6.22 4.99 1.1 7 2.5A13.94 13.94 0 0 1 15 0h4c.34 0 .67.01 1 .04v2A12 12 0 0 0 7.17 12h5.12A7 7 0 0 1 20 7.07V14a5 5 0 0 0-3-4.58A5 5 0 0 0 14 14H0V7.07c.86.12 1.67.4 2.4.81.75-1.52 1.76-2.9 2.98-4.05C3.79 2.83 1.96 2.2 0 2.04v-2z'/%3E%3C/g%3E%3C/svg%3E");
    .info {
      @media screen and (max-width: 500px) {
        font-size: 0.8em;
      }
    }
  }
  .ordering-menu {
    background-color: #232125;
    background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233b393e' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    color: $white;
  }


  .landing-page, .top-banner {
    font-family: $sigFont;
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 0em 0;
    margin: 0;
    display: block;
    min-height: 200px;

    font-size: 1.5em;
    .avatar {
      img {
        display: block;
        box-shadow: 0px 0px 0px 6px black,
                    0px 0px 0px 10px white,
                    0px 0px 0px 14px $red;
        max-width: 400px;
      }
    }
    .text {

    }
  }
  .landing-page {
    background: black;
    // background: url(https://res.cloudinary.com/dgps1radv/image/upload/q_74/v1551738878/danstuff/backgound.jpg);
    background-size: cover;
    background-position: center;
    color: white;
    font-weight: 900;
    .text {
      display: flex;
      .location {
        width: auto;
        font-size: 0.7em;
        a.phone-link {
          color: white;
        }
      }
    }
   
    .order-action {
      font-size: 0.7em;
    }
    
  }
  


   .top-banner, .landing-page {
     display: flex;
     align-items: center;
     justify-content: center;

     h1 {
       font-size: 1.5em;
       padding: 0 0.5em;
     }
     .text {
       // color: white;
     }
   }
  .hero-logo {
    margin: 0 auto;
    max-width: 150px;
    @media screen and (max-width: 500px) {
      // max-width: 100px;
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .landing-page .text {
    // color: white;
    text-align: center;
    // background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
    padding: 0.5em 0.8em;
    font-size: 0.8em;
  }
  .section {
    h2 {
      // text-align: left;
      // color: $red;
      font-weight: 900;
      font-size: 2em;
      padding: 0;
    }
  }
  .front-page {
    max-width: 800px;
    background:  $black;
    color: white;
    margin: 0 auto;
    margin-top: -1.3em;
    border-radius: 0.4em 0.4em 0 0;
    box-shadow: 0px 0px 0px 3.5px  white,
                0px 0px 0px 8.5px black,
                0px 0px 0px 13px $red;
  }
  a.checkout-button.button {
    background: #e6001c;

    font-weight: 600;
    box-shadow: none;
    border: 2px solid #eee;

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  font-family: $sigFont;
  font-weight: 600;
  font-size: 1em;
  background: #e6001c;
  color: white;
}
.category-name .text::before {
  content: "• "
}
.category-name .text::after {
  content: " •"
}
.menu-category-list-view {
  .menu-item {
    // background: rgba(255,255,255,0.5);
    .food-name {
      font-size: 1.05em;
      line-height: 1em;
    }
  }
}
.top-banner {
  // height: 30vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.pages-nav {
  // display: none;
  font-family: $sigFont;
  background: black;
  font-weight: 900;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 480px) {
    font-size: 4vw;
  }
}

a.page-link {
  text-decoration: none;
  color: white;
  display: block;
  padding: $paddingY 0.3em;
  padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
  // box-sizing: border-box;
  border-bottom: $borderWidth solid black;
  &:hover, &.active {
    border-bottom: $borderWidth solid $red;
  }
}

.specials {
  text-align: center;
  margin: 0.2em 0;
  .specials-text {
    display: inline-block;
    background: linear-gradient(90deg, #f8ff00 0%, #3ad59f 100%);
    color: black;
    padding: 0.4em 0.4em;
    border-radius: 0.2em;
  }
}

a.order-button {
  // font-size: 0.8em;
  display: inline-block;
  color: white;
  text-decoration: none;
  font-weight: 700;
  display: inline-block;
  background: $red;
  padding: 0.3em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.1em;
  box-shadow: 1px 1px #770523, 3px 3px #770523, 5px 5px #770523, 7px 7px #770523;
  transition: all 0.12s ease 0s;
  &:hover {
    transform: translate(5px, 5px);
    box-shadow: none
  }
  &.disabled:hover {
    transform: none;
    box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
  }
  &.disabled {
    background: gray;
    box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
  }
}
.ordering-header {
  background: rgba(0,0,0,0.5);
  padding: 0.3em 0.5em;
}

.ordering-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  // background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1540691096/PablitosKitchen_Hero-1_FullSize_blog_960x540_sc2pmh.jpg);
  background-size: cover;
  background-position: center bottom;
  text-align: center;
  // font-family: "Chewy";
  color: white;
  // height: 300px;
}
.pages-nav.main {
  ul {
    justify-content: center;
  }
}
// HOME
.location-header {
  padding: 1em 0;
  background: black;
  color: white;

}
.locations-header {
  background: url(https://afag.imgix.net/pablitos-tacos-burbank/hero.jpeg?w=1200) no-repeat;
  background-size: cover;
  background-position: center;
  // color: white;
  min-height: 250px;
  padding: 2em 0.5em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  .site-logo {
    width: 200px;
    margin: 0;
  }
  .header {
    text-align: center;
  }
}

h4.concept-header {
  font-size: 1.2em;
  font-weight: 900;
  padding: 0.5em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .location-label {
    font-size: 0.5em;
    font-weight: 600;
    background-color: black;
    color: white;
    border-radius: 1em;
    padding: 0.4em 0.4em;

  }
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.gallery-item {
  width: 150px;
  padding: 0.3em;
  img {
    width: 100%;
    display: inline-block;
    border-radius: 0.5em;
  }
}
.locations {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 15em;
}
.location {
  // border: 1px solid black;
  // width: 50%;
  box-sizing: border-box;
  // background-color: rgba(0,0,0,0.3);
  margin: 1em;
  padding: 1em 0.5em;
  border-radius: 0.5em;
  text-align: center;
  // display: flex;
  // justify-content: center;
  font-size: 1em;
  font-weight: 400;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .title {
    font-weight: 900;
    font-size: 1.8em;
    padding: 0;
    background-color: black;
    color: white;
    border-radius: 0.5em;
    padding: 0.2em 0;
  }
  .phone {
    margin-top: 0.5em;
  }
  a.phone-link {
    color: $red;

  }
  .address {
    margin-top: 0.5em;
  }
  .hours-of-operation {

    // text-align: left;
    margin-top: 0.5em;
    .day {
      text-align: left;
      display: inline-block;
      min-width: 5.5em;
    }
  }
}
  // .ordering-menu {
  //   margin-top: 0em !important;
  // }

  .promo {
    // animation: shockwaveJump 1s ease-out;
    padding: 4em 0 1em 0;
      h3 {
        margin: 0;
        padding: 0;
      }
      ul {
        padding-left: 1em;
        list-style: disc;
      }
      .text {
        max-width: 500px;
        margin: 0 auto;
        background: rgba(255,255,255,0.5);
        padding: 0.7em;
        border: 2px solid black;
      }
      a {
        color: $red;
        font-weight: 600;
        text-decoration: underline;
      }
  }




}

// .highlight {
//   display: inline-block;
//   background: 
// }

.old-locations {
  h2 {
    font-size: 1.5em !important;
    margin-bottom: 1em;
  }
  strong {
    font-weight: 900;
  }
  max-width: 500px;
  margin: 0 auto;
  padding: 2em 0.5em;
  padding-bottom: 3em;
}

.pablitostacos {
  .pages-nav {
    display: block;
  }
}
